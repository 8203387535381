// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-photography-list-js": () => import("./../src/templates/photography-list.js" /* webpackChunkName: "component---src-templates-photography-list-js" */),
  "component---src-templates-photography-js": () => import("./../src/templates/photography.js" /* webpackChunkName: "component---src-templates-photography-js" */),
  "component---src-templates-basepage-js": () => import("./../src/templates/basepage.js" /* webpackChunkName: "component---src-templates-basepage-js" */),
  "component---src-templates-video-js": () => import("./../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

